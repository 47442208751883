@import "../variables";

.GallerySection {
  // .carousel-heading1 {
  //   .our-gallery {
  //     font-size: 3rem;
  //     font-family: "Cinzel Decorative", serif;
  //     font-weight: 400;
  //     color: var(--theme-color-orange-dark);
  //     // margin-bottom: rem;
  //   }
  // }

  // .custom-overlay__caption {
  //   background-color: rgba(0, 0, 0, 0.8);
  //   // max-height: 240px;
  //   overflow: hidden;
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   color: white;
  //   padding: 2px;
  //   font-size: 90%;
  //   height: 100%;
  //   align-content: center;
  // }

  // .custom-overlay__tag {
  //   word-wrap: break-word;
  //   display: inline-block;
  //   background-color: white;
  //   height: auto;
  //   font-size: 75%;
  //   font-weight: 600;
  //   line-height: 1;
  //   padding: .2em .6em .3em;
  //   border-radius: .25em;
  //   color: black;
  //   vertical-align: baseline;
  //   margin: 2px;
  // }

  // .ReactGridGallery_custom-overlay {
  //   transition: 500ms ease-in-out;
  // }
  .gallary-image-container {
    cursor: pointer;
    min-height: 18rem;
    @media screen and (max-width: $xxl) {
			min-height: 15rem;
		}

    @media screen and (max-width: $xl) {
			min-height: 13rem;
		}
    @media screen and (max-width: $lg) {
			min-height: 13rem;
		}
    @media screen and (max-width: $md) {
			min-height: 15rem;
		}
    @media screen and (max-width: $sm) {
			min-height: 25rem;
		}

    background-size: cover;
    background-position: center;

    transition: transform 0.3s ease, opacity 0.3s ease;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      /* Dark overlay on hover */
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover {
      transform: scale(1.05);
      /* Scale effect */
      .overlay {
      opacity: 1;

      }

    }

    &:hover::after {
      opacity: 1;
      /* Show overlay on hover */
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      /* Dark overlay */
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

}