.gallery {
    .gallery-wrapper {
        background-image: url("../assets/foodstyling.jpg");
        height: 100vh;
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;

        .gallery-services {
            padding-top: 275px;
            color: var(--font-color);
            font-size: 5rem;
            font-family: 'Cinzel Decorative', cursive;
        }

        .gallery-services-2 {
            font-size: 60px;

        }
    }

    // .our-gallery {
    //     h1 {
    //         font-size: 3.5rem;
    //         color: var(--theme-color-orange-light);
    //         font-family: 'Cinzel Decorative', cursive
    //     }

    // }
}

@media (min-width:400px) and (max-width:940px) {
    .gallery {
        .gallery-wrapper {
            background-image: url("../assets/foodstyling.jpg");
            height: 100vh;
            background-size: cover;
            background-repeat: no repeat;
            background-color: var(--blend-mode-color);
            background-blend-mode: overlay;
            background-position: center;

            .gallery-services { 
                padding-top: 275px;
                color: var(--font-color);
                font-size: 5rem;
                font-family: 'Cinzel Decorative', cursive;
            }

            .gallery-services-2 {
                font-size: 60px;

            }
        }
    }
}