@keyframes animateTop {
	0% {
		transform: translateY(-100px);
		opacity: 0.5;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes animateBottom {
	0% {
		transform: translateY(100px);
		opacity: 0.5;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
.animate-top {
	animation: 500ms ease-out 0s 1 animateTop;
}
.animate-bottom {
	animation: 500ms ease-out 0s 1 animateBottom;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: var(--theme-color-orange-dark);
}