.footer-wrapper {
    background-image: url(../../assets/footerimage.avif);
    background-color: rgb(0 0 0 / 65%);
    background-blend-mode: overlay;
    background-size: cover;

    background-repeat: no-repeat;
    width: 100%;
    background-attachment: fixed;
    background-position: center;

    .footer {
        .footer-list-item {
            color: var(--theme-color-orange-light);


        }

        ul {
            &.nav {
                border-bottom: var(--bs-border-width) var(--bs-border-style) var(--theme-color-orange-light);
            }

            li {
                list-style-type: none;

                a {
                    text-decoration: none;
                    color: var(--theme-color-orange-light);
                }


            }

        }

        .copy-right {
            color: var(--theme-color-orange-light);

        }
    }
}