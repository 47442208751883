.services {
    .services-wrapper {
        background-image: url("../assets/catering-services5.JPG");
        height: 100vh;
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;

        .our-services {
            padding-top: 275px;
            color: var(--theme-color-orange-light);
            font-size: 60px;
        }

        .our-services-2 {
            font-size: 60px;
        }
    }

    .services-day {
        .services-heading-1 {
            color: var(--theme-color-orange-light);
        }
    }

    .services-offer {
        background-image: url("../assets/service1.jpg");
        height: 75vh;
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;

        .our-services {
            padding-top: var(--section-padding);
            // padding-top: 275px;
            color: var(--theme-color-orange-light);
            font-size: 3rem;
            font-family: 'Cinzel Decorative', cursive;
        }

        .our-services-2 {
            font-size: 3.5rem;

        }
    }

    .services-stalls {
        .stall-img {
            width: 100%;
        }

        .service-custom-heading {
            .about-heading {
                margin-top: 2rem;
                font-size: 2rem;
                color: var(--theme-color-orange-dark);
            }
        }

        .service-custom-para {
            font-size: 1.4rem;
        }

        a {
            font-family: 'Suranna', serif;
            font-size: 1.6rem;
            color: #000;
            text-decoration: none;
        }

    }
}

@media (min-width:400px) and (max-width:960px) {
    .services-stalls {
        .service-custom-heading {
            h3 {
                font-size: 1.5rem;
                // margin-top: 2rem;
                font-family: 'Cinzel Decorative', cursive;
                color: var(--font-color);
            }


        }

        .service-custom-para {
            font-size: 1rem;
            text-align: justify;

        }
    }

    .services-offer {
        background-image: url("../assets/service1.jpg");
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;

        .our-services {
            // padding-top: 275px;
            // color: var(--theme-color-orange-light);
            // font-size: 60px;
            // font-family: 'Cinzel Decorative', cursive;
        }

        .our-services-2 {
            // font-size: 60px; 

        }
    }
}