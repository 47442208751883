@import "../variables";

.contact-us {
    .contact-wrapper {
        background-image: url("../assets/Contact.jpg");
        height: 100vh;
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;

        .our-contact {
            padding-top: 275px;
            color: var(--font-color);
            font-size: 70px;
            font-family: "Cinzel Decorative", serif;
        }

        .our-contact-para {
            font-size: 1rem;
        }
    }

    .contact-form {

        // background-image: url(../assets/contact-template.jpg);
        .custom-heading {
            font-family: 'Cinzel Decorative', cursive;
            text-transform: none;
            font-size: 25px;
            color: var(--theme-color-orange-light);
        }

        .img-contact {
            height: 100%;
            background-position: center;
            min-height: 50vh;

        }


    }
}

@media (max-width:$md) {
    .img-contact {
        margin-top: 1rem;

    }
}

// @media (min-width:374px) and (max-width:766px){
//     .contact-form{
//         .img-contact{
//             margin-top: 1rem;
//             width: 100%;
//             height: 60vh;
//         }
//     }
// }
// @media (min-width:767px) and (max-width:1000px){
//     .contact-form{
//         .img-contact{
//             // margin-top: 1rem;
//             width: 100%;
//             height: 60vh;
//         }
//     }
// }