.About {
    .about-wrapper {
        background-image: url("../assets/Staff.jpeg");
        height: 100vh;
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;
        background-position: center;
    }

    .about-anil-caters {
        .about-heading {
            font-family: "Cinzel Decorative", serif;
            font-size: 3rem;
            color: var(--theme-color-orange-dark);
        }

        .about-heading2 {
            font-family: 'Suranna', serif;
            font-size: 36px;
            color: var(--theme-color-orange-light) // line-height: 42px;
                // padding-bottom: 10px;
        }

        .para {
            font-size: 1.3rem;
            text-align: justify;
        }
    }

    .indoor-wrapper {
        background-image: url("../assets/indore.jpg");
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;
        color: #fff;
        padding-top: var(--section-padding);
        padding-bottom: var(--section-padding);
        width: 100%;

        .indoor-heading {
            

        }

        .indoor-heading-2 {
            font-size: 3rem;
            color: #fff;

        }
    }

    .staff-wrapper {
        background-image: url("../assets/catering-services4.jpg");
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;
        color: #fff;
        padding-top: var(--section-padding);
        padding-bottom: var(--section-padding);

        .indoor-heading {
            // padding-top: 275px;
            // font-size: 3rem;
            // color: var(--theme-color-orange-light);
            // font-family: 'Cinzel Decorative', cursive;
            // text-transform: none;
        }

        .indoor-heading-2 {
            font-size: 3rem;
            color: #fff;
        }
    }
}

@media (min-width:375px) and (max-width:475px) {
    .About {
        .about-wrapper {
            .our-story {
                padding-top: 200px;
                color: var(--font-color);
                font-size: 70px;
                font-family: "Cinzel Decorative", serif;
            }
        }
    }
}

@media (min-width:995px)and (max-width:1480px) {
    .About {
        .about-anil-caters {
            .about-heading {
                font-family: "Cinzel Decorative", serif;
                font-size: 3rem;
                color: var(--);
            }


            .para {
                font-size: 1rem;
                text-align: justify;
                margin-bottom: rem;
            }
        }
    }
}

@media (min-width:400px) and (max-width:900px) {
    .About {
        .about-anil-caters{
            .about-heading2{
                font-family: "Suranna", serif;
                font-size: 36px;
                color: var(--theme-color-orange-light);
                margin-top: 2rem;
            }
        }
        .about-wrapper {
            background-image: url("../assets/Staff.jpeg");
            height: 100vh;
            background-size: cover;
            background-position: center;
        }
        
    }
}