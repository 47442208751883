@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");

@font-face {
	font-family: "Cinzel Decorative", serif;
	font-weight: 400;
	font-style: normal;
	src: url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Trocchi&display=swap");
}

:root {
	// --bs-link-color-rgb: var(--font-color);
	--section-padding: 8rem;
	--theme-color-red: #b61222;
	--theme-color-orange-dark: #fd8f32;
	--theme-color-orange-light: #fda75c;
	--blend-mode-color: rgba(0, 0, 0, 0.431372549);
	
}

@import "../variables";
@import "./Animate";



.frontend {
	font-family: Trocchi;

	.navbar-logo {
		width: 12rem;
		// width: 20%;
	}

	.section-heading {
		font-family: "Cinzel Decorative", cursive;
		font-size: 3rem;
		color: var(--theme-color-orange-dark);
		animation: 500ms ease-out 0s 1 animateTop;
		@media screen and (max-width: $md) {
			font-size: 2rem;
		}
	}

	.heading-underline {
		animation: 500ms ease-out 0s 1 animateTop;
		display: flex;

		span:first-child {
			position: relative;
			width: 40%;

			&::before,
			&::after {
				content: "";
				height: 1px;
				position: absolute;
				border: 1px solid var(--theme-color-orange-dark);

			}

			&::before {
				width: 100%;
				left: 0;
			}

			&::after {
				width: calc(100% - 20px);
				left: 20px;
				margin-top: 6px;

			}
		}

		span:nth-child(2) {
			width: 20%;

			img,
			svg {
				width: 100%;
			}
		}

		span:last-child {
			width: 40%;
			position: relative;

			&::before,
			&::after {
				content: "";
				height: 1px;
				position: absolute;
				border: 1px solid var(--theme-color-orange-dark);

			}

			&::before {
				width: calc(100% - 20px);
				right: 20px;
				margin-top: 6px;
			}

			&::after {
				width: 100%;
				right: 0;
			}
		}
	}

	.heading-underline-small {
		display: flex;

		span:first-child {
			position: relative;
			width: 40%;

			&::before,
			::after {
				content: "";
				height: 1px;
				position: absolute;
				border: 1px solid var(--theme-color-orange-dark);
			}

			&::before {
				width: 100%;
				left: 0;
			}
		}

		span:nth-child(2) {
			&.circle {
				position: relative;

				&::after {
					// border: 1px solid var(--theme-color-orange-dark);
					background-color: var(--theme-color-orange-dark);
					border-radius: 50%;
					width: 10px;
					height: 10px;
					content: "";
					position: absolute;
					left: calc(50% - 5px);
				}
			}

			width: 20%;
			margin-top: -5px;
		}

		span:last-child {
			position: relative;
			width: 40%;

			&::before,
			::after {
				content: "";
				position: absolute;
				height: 1px;
				border: 1px solid var(--theme-color-orange-dark);
			}

			&::before {
				width: 100%;
				right: 0;
			}
		}

	}

	.banner-heading {
		font-family: "Cinzel Decorative", cursive;
		font-family: "Cinzel Decorative", cursive;
		font-size: 3rem;
		// margin-top: 8rem;
		animation: 500ms ease-out 0s 1 animateTop;
		h1 {
			// margin-top: 8rem;
			font-size: 5rem;
			// font-weight: 900;
			color: #fff;

			@media screen and (max-width: $md) {
				font-size: 3rem;
			}
		}

		h6 {
			font-weight: 900;
			color: #fff;
		}
	}
}