.Header {
    transition: 2ms ease-in-out fixed;

    .bg-body-tertiary-cst {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: "transparent";
        transition: 500ms ease-in-out;
        z-index: 9999;

        @media screen and (max-width: 992px) {
            background-color: rgb(0 0 0 / 70%);
        }
    }

    .navbar-logo {
        transition: 500ms ease-in-out;
    }

    .navbar-collapse.collapse.show {
        height: 100vh;
        transition: height 0.2s ease-in-out;
        // transition: var(--bs-navbar-toggler-transition);
    }

    .navbar-nav {
        .nav-link {
            color: var(--theme-color-orange-light);
            font-size: larger;
            margin: 0.2rem;
            border-bottom: 0.2rem solid transparent;
            transition: 300ms ease-in-out;

            &:hover,
            &:focus,
            &.active {
                color: var(--theme-color-orange-light);
                opacity: 0.7;
                border-bottom: 0.2rem solid red;
                transition: 300ms ease-in-out;
            }
        }
    }

    .navbar-toggler {
        background-color: var(--theme-color-orange-light);
    }

}