.Home {
    .caters-chef {
        /* Set a specific height */
        min-height: 500px;

        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;


        background-image: url("../assets/catering-services9.jpg");
        background-size: cover;
        // width: 100%;
        height: 100vh;
        background-position: center;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;
        overflow: hidden;

        .caters-chef-img {
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: -1;
            opacity: 1;
        }
    }

    .caters-dinner {
        background-image: url("../assets/seeds.jpg");
        background-size: cover;
        width: 100%;
        padding-top: var(--section-padding);
        padding-bottom: var(--section-padding);
        background-position: center;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;

        .our-philospy {}

        .our-philospy-2 {
            font-size: 35px;
        }

        .our-philospy-para {
            font-size: 1.5rem;

        }

    }

    .carousel-heading {
        color: var(--theme-color-orange-light);
    }

    .about-my-caters {
        background-image: url("../assets/Aboutmycaters.jpg");
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;
        color: #fff;


        .about-heading {}

        padding-top: var(--section-padding);

    }

    .our-execution {
        font-family: 'Crimson Text', serif;
        padding-top: var(--section-padding);
        padding-bottom: var(--section-padding);

        .carousel-heading1 {

            color: var(--theme-color-orange-light);


        }

    }

    .details-matter {
        background-image: url("../assets/catering-services5.JPG");
        background-position: center;
        background-size: cover;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;
        color: #fff;
        padding-top: var(--section-padding);
        padding-bottom: var(--section-padding);

        .details-heading {
            color: var(--theme-color-orange-dark);
            font-size: 60px;
        }

        .details-heading-2 {
            font-size: 3rem;
            font-family: 'Suranna', serif;

        }

        .details-para {
            font-size: 1.6rem;
        }
    }

    .our-gallery {
        .our-gallery-images {
            img:hover {
                filter: grayscale(1);
            }
        }
    }

    section {
        column-width: 300px;
        column-gap: 5px;
        padding: 5px;
    }

    .carousel-indicators {
        bottom: -3rem;
    }

    .carousel-indicators [data-bs-target] {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border-top: unset;
        border-bottom: unset;
        border: 1px solid black;

    }
}

.card-provider {
    background-image: url(../assets/serviceprovider.jpg);
    background-color: var(--blend-mode-color);
    background-blend-mode: overlay;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;

    .card-holder {
        .card-owner {
            color: var(--theme-color-orange-dark);
        }

        .card-contact-form {
            .card-list {
                text-decoration: none;
                list-style-type: none;
                color: inherit;
            }

        }
    }
}

@media (min-width:400px) and (max-width:940px) {
    .card-provider {
        background-image: url(../assets/serviceprovider.jpg);
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;

        .card-holder {
            padding-bottom: 2rem;

            .card-owner {
                color: var(--theme-color-orange-dark);
                font-size: 1.5rem;
            }

            .card-contact-form {
                .card-list {
                    text-decoration: none;
                    list-style-type: none;
                    color: inherit;
                }


            }
        }
    }

    .details-matter {
        background-image: url("../assets/homesection.jpg");
        background-size: cover;
        background-position: center;
        background-color: var(--blend-mode-color);
        background-blend-mode: overlay;
        color: #fff;
        padding-top: var(--section-padding);
        padding-bottom: var(--section-padding);

        .details-heading {
            color: var(--theme-color-orange-dark);
            font-size: 2rem;
        }

        .details-heading-2 {
            font-size: 1rem;
            font-family: 'Suranna', serif;

        }

        .details-para {
            font-size: 1rem;
        }
    }
}