.achievement {
    .achievement-wrapper {
        background-image: url("../assets/galleryprofile.jpg");
        height: 750px;
        background-size: cover;
        background-color: #0000009c;
        background-blend-mode: overlay;

        .achievement-services {
            padding-top: 275px;
            color: var(--font-color);
            font-size: 5rem;
            font-family: 'Cinzel Decorative', cursive;
        }

        .achievement-services-2 {
            font-size: 60px;

        }
    }
}